import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import uuid from 'react-uuid'

import DefaultLayout from '../../layouts/DefaultLayout'
import { Map, AddToMapButton, MapToggleButton } from '../../components/Map'
import { getTags, getPosts } from '../../api'
import { findTagColor } from '../../util'

const ViolationPage = () => {
  const { t } = useTranslation()
  const [list, setList] = useState([])
  const [markers, setMarkers] = useState([])
  const [allMarkers, setAllMarkers] = useState([])

  const setActive = useCallback(
    (givenKey) => {
      return (e) => {
        e.preventDefault()

        const updatedList = list.map((item) => {
          return {
            ...item,
            active: item.key === givenKey ? !item.active : item.active,
          }
        })

        setList(updatedList)
      }
    },
    [list]
  )

  const fetchData = async () => {
    const { tags } = await getTags()

    const list = tags
      .find(({ tagName }) => tagName.toLowerCase() === 'violation')
      .children.map((item) => {
        const key = item.tagName
        const color = findTagColor(item.tagName)
        const id = item.id

        return {
          id,
          key,
          color,
          active: true,
        }
      })

    const { posts } = await getPosts({
      filter: {
        tag: {
          in: list.map(({ id }) => id),
        },
      },
    }).then((data) => {
      const posts = data.posts.map((item) => {
        const { location, ...rest } = item
        const latlng = location
        const tags = item.tags.map((key) => {
          return list.find((tag) => tag.key === key)
        }).map(({ active, ...item}) => {
          return item
        })

        return {
          ...rest,
          tags,
          latlng,
        }
      })

      return {
        ...data,
        posts,
      }
    })

    setList(list)
    setAllMarkers(posts)
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    setMarkers(
      allMarkers.filter(({ tags }) => {
        const activeList = list.filter(({ active }) => active)

        for(const tag of activeList) {
          if (tags.map(({key}) => key).includes(tag.key)) {
            return true
          }
        }

        return false
      })
    )
  }, [list, setAllMarkers, allMarkers])

  return (
    <DefaultLayout>
      <div style={{
          height: 'calc(100vh - 112px)'
        }} className="overflow-hidden relative z-0">
        <div className="absolute w-full top-0 z-10">
          <nav className="container mx-auto flex flex-row-reverse items-start items-start justify-center xl:justify-end gap-x-5 space-x-reverse">
            <div className="flex-0 flex flex-col bg-black text-white text-xl px-7 py-2 xl:py-5 items-center space-y-1 font-bold">
              <span>{t('violations map')}</span>
            </div>
          </nav>
        </div>
        <div className="absolute w-full bottom-0 z-10">
          <div className="container mx-auto flex flex-wrap xl:flex-nowrap flex-row justify-between items-center xl:items-end xl:gap-x-5">
            {list.map(({ key, color, active }) => (
              <div key={uuid()} className="flex-1 xl:w-auto">
                <MapToggleButton
                  className={`bg-${color} overflow-hidden`}
                  data={{ setActive, active, key }}
                />
              </div>
            ))}
            <div className="flex-0 order-last xl:order-none w-full xl:w-auto">
              <AddToMapButton />
            </div>
          </div>
        </div>
        <Map className="w-full h-full absolute" data={markers} />
      </div>
    </DefaultLayout>
  )
}

export default ViolationPage
